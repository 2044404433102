import React from "react";
import { Route, Routes } from "react-router-dom";
import Plan from "./pages/Plan";
import ContactSales from "./pages/ContactSales";
import DownloadLink from "./pages/DownloadLink";
import Unsubscribe from "./pages/Unsubscribe";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#24A789",
    },
    secondary: {
      main: "#FFFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          width: 260,
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Plan />} />
        <Route path="/ContactSales" element={<ContactSales />} />
        <Route path="/DownloadLink" element={<DownloadLink />} />
        <Route path="/Unsubscribe" element={<Unsubscribe />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
