import * as React from "react";
import { useNavigate } from "react-router-dom";
import PricingCard from "./PlanCard";
import "../style/MyCard.css";
import PurchaseModal from "./PurchaseModal";


import {
  Box,
  Container,
  Grid,
} from "@mui/material";

export default function CardLayout() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  function goToContactSales() {
    navigate("/ContactSales");
  }

  function product1() {
    window.open("https://buy.stripe.com/test_3cs28i6xIfsHd0I7ss");
  }
  function product2() {
    window.open("https://buy.stripe.com/test_7sI4gqf4efsH4uc3ce");
  }

  return (
    <Box
      component="div"
      sx={{
        marginTop: 6,
        marginBottom: 50,
        height: 400,
      }}
    >
      <Container Fixed>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={4} md={12}>
            <PricingCard
              recommended={false}
              planName={"Basic"}
              icon={"assets/user1.png"}
              price={"₹399/yr"}
              list={[
                "Includes 1 seat, 4 test/month",
                "Spot check of Hb level",
                "Knowledge assets",
              ]}
              primaryBtn={"Start with 7 days free trial"}
              primaryBtnFun={product1}
              secondaryBtn={"Subscribe"}
              secondaryBtnFun={handleOpen}
              extrabtn={true}
            />
          </Grid>

          <Grid item xs={12} lg={4} md={12}>
            <PricingCard
              recommended={true}
              planName={"Family/Group"}
              icon={"assets/user2.png"}
              price={"₹1450/yr"}
              list={[
                "Includes 4 seat, 4 test/seat/month",
                "Spot check and trend of Hb level",
                "Knowledge assets",
              ]}
              primaryBtn={"Start with 7 days free trial"}
              primaryBtnFun={product2}
              secondaryBtn={"Subscribe"}
              secondaryBtnFun={handleOpen}
              extrabtn={true}
            />
          </Grid>

          <Grid item xs={12} lg={4} md={12}>
            <PricingCard
              recommended={false}
              planName={"Govt/NGO/Business"}
              icon={"assets/user3.png"}
              price={"Everything in individual /Family, plus"}
              list={[
                "Team onboarding & ongoing tech support",
                "Custom templates & automation",
                "SSO plus additional security",
                "Advanced admin analytics",
                "No limits on all features",
                "API access*",
              ]}
              primaryBtn={"Contact Sales Person"}
              primaryBtnFun={goToContactSales}
              extrabtn={false}
            />
          </Grid>
        </Grid>
      </Container>

      <PurchaseModal open={open} onClose={handleClose}/>

    </Box>
  );
}
