import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import { createUser, verifyUser } from "../Scripts/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

function PurchaseModal(props) {
  const { open, onClose } = props;

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [getcode, setGetcode] = useState("");

  const [isEmail, setIsEmail] = React.useState(true);
  const [isOtp, setIsOtp] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const [userdata, setUserdata] = useState(true);

  useEffect(() => {
    const clearOtpAfterTimeout = () => {
      setCode('');
    };
   setTimeout(clearOtpAfterTimeout, 600000);
  }, [code]);

  const handleClick = async () => {
    if (isEmail) {
      if (email.trim() === "") {
        setErrorMessage("This field is required!");
        // console.log("empty");
        setIsValidEmail(false);
      } else {
        // console.warn(email);
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const isValid = regex.test(email);
        setIsValidEmail(isValid);
        if (!isValid) {
          setErrorMessage("Please enter a valid email address.");
        } else {
          setIsEmail(false);
          setIsOtp(true);
          const data = await createUser(email);
          // console.log(data);
          // console.log(data.code);
          const resCode = data.code;
          setCode(resCode);
          // console.log(data.user_data);
          const resdata = data.user_data;

          if (resdata.length === 0) {
            setUserdata(false);
          }
        }
      }
    } else {
      // console.warn(code);

      if (getcode.trim() === "") {
        setErrorMessage("This field is required!");
        // console.log("empty code box");
        setIsValidCode(false);
      } else {
        if (code !== getcode) {
          setErrorMessage(
            "This verification code is incorrect. Please try again!"
          );
          setIsValidCode(false);
        } else {
          if (userdata === false) {
            const resdata = await verifyUser(email, "");
            // console.log(resdata);
            // console.log(resdata.statusCode);
            const stscode = resdata.statusCode;
            if (stscode === 200) {
              setIsOtp(false);
              setIsLogin(true);
            } else {
              alert("Opps something went wrong!");
            }
          } else {
            setIsOtp(false);
            setIsLogin(true);
          }
        }
      }
    }
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box><img
            src="assets/cross.png"
            alt="logo"
            height="20"
            width="20"
            onClick={onClose}
          /></Box>
          <Box
            id="modal-modal-description"
            sx={{ mt: 2, mb: 2, fontSize: 18, fontWeight: "bold" }}
          >
            First we need to validate your account
          </Box>

          {isEmail && (
            <Box id="modal-modal-title" variant="h6" component="h2">
              <TextField
                required
                fullWidth
                id="outlined-basic"
                label="Email Address"
                placeholder="Enter your email address"
                variant="outlined"
                sx={{ input: { color: "grey", label: { color: "white" } } }}
                onChange={(i) => setEmail(i.target.value)}
                helperText={!isValidEmail ? errorMessage : ""}
                error={!isValidEmail}
              />
            </Box>
          )}

          {isOtp && (
            <Box>
              <Box id="modal-modal-title" variant="h6" component="h2">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  value={email}
                  sx={{ input: { color: "grey" } }}
                />
              </Box>
              <Box id="modal-modal-title" variant="h6" component="h2">
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  label="Verification code"
                  placeholder="Enter the verification code sent to your email"
                  variant="outlined"
                  onChange={(v) => setGetcode(v.target.value)}
                  helperText={!isValidCode ? errorMessage : ""}
                  error={!isValidCode}
                />
              </Box>
            </Box>
          )}

          {isLogin && (
            <Box>
              <Box sx={style}>
                <Box
                  id="modal-modal-description"
                  sx={{ mt: 2, mb: 3, fontSize: 18, color: "green" }}
                  display="flex"
                  flexDirection="row"
                >
                  {email}
                  <DoneIcon sx={{ color: "green", fontSize: 23 }} />
                </Box>
                <Box id="modal-modal-description" sx={{ mb: 2, fontSize: 16 }}>
                  You already have an existing plan :
                </Box>
                <Box
                  id="modal-modal-description"
                  sx={{
                    fontSize: 16,
                    border: "1px solid",
                    textAlign: "center",
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  Basic - Plan ( Valid till 25th Nov )
                </Box>
                <Box
                  id="modal-modal-description"
                  sx={{
                    fontSize: 16,
                    textAlign: "center",
                    mt: 3,
                    color: "orange",
                  }}
                >
                  <WarningIcon />
                  <Typography>
                    If you wish to acquire an additional plan, kindly consider
                    signing in using a different email address.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {isEmail && (
            <Box>
              <Button
                variant="contained"
                className="modal-btn"
                onClick={handleClick}
              >
                Next
              </Button>
            </Box>
          )}
          {isOtp && (
            <Box>
              <Button
                variant="contained"
                className="modal-btn"
                onClick={handleClick}
              >
                Verify
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
export default PurchaseModal;
