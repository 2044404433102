import { Box, Typography } from "@mui/material";
import CardLayout from "../components/CardLayout";
import "../style/Global.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Plan() {
  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: 12 }}>
        <Typography className="heading">Flexible Plans & Pricing</Typography>
      </Box>
      <CardLayout />
      <Footer />
    </Box>
  );
}

export default Plan;
