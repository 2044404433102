import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Typography, Container, TextField, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { checkUser, removeUser } from "../components/Scripts/UnsubscribeApi";

function Unsubscribe() {
  const [stepEmail, setStepEmail] = useState(true);
  const [stepOtp, setStepOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [getcode, setGetcode] = useState("");
  const [notUser, setNotUser] = useState(false);

  useEffect(() => {
    const clearOtpAfterTimeout = () => {
      setCode('');
    };
   setTimeout(clearOtpAfterTimeout, 600000);
  }, [code]);


  const handleClick = async () => {
    if (stepEmail) {
      if (email.trim() === "") {
        setMessage("This field is required!");
        // console.log("empty");
        setIsValidEmail(false);
      } else {
        // console.warn(email);
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const isValid = regex.test(email);
        setIsValidEmail(isValid);
        if (!isValid) {
          setMessage("Please enter a valid email address.");
        } else {
          const data = await checkUser(email);
          // console.log(data);
          // console.log(data.user_data);
          const resdata = data.user_data;
          if (resdata.length === 0) {
            setMessage("You are already unsubscribed!");
            setStepEmail(false);
            setNotUser(true);
          } else {
            // console.log(data.code);
            const resCode = data.code;
            setCode(resCode);
            setStepEmail(false);
            setStepOtp(true);
          }
        }
      }
    } else {
      // console.warn(code);

      if (getcode.trim() === "") {
        setMessage("This field is required!");
        // console.log("empty code input box");
        setIsValidCode(false);
      } else {
        if (code !== getcode) {
          setMessage("This verification code is incorrect. Please try again!");
          setIsValidCode(false);
        } else {
          const resdata = await removeUser(email);
          // console.log(resdata);
          // console.log(resdata.statusCode);
          const stscode = resdata.statusCode;
          if (stscode === 200) {
            setMessage(
              "Success! You are deleted from our system. Thank you for using NiADA."
            );
            setStepOtp(false);
            setNotUser(true);
          } else {
            alert("Opps something went wrong!");
          }
        }
      }
    }
  };
  return (
    <>
      <Header />
      {!notUser && (
        <Box sx={{ marginTop: 12 }}>
          <Typography className="heading">Unsubscribe Here</Typography>
        </Box>
      )}
      <Container sx={{ mt: 10, mb: 10 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {stepEmail && (
            <>
              <TextField
                id="outlined-basic"
                placeholder="Enter your email"
                variant="outlined"
                sx={{ width: 300 }}
                onChange={(i) => setEmail(i.target.value)}
                helperText={!isValidEmail ? message : ""}
                error={!isValidEmail}
              />
              <br />
              <Button variant="contained" className="btn" onClick={handleClick}>
                Next
              </Button>
            </>
          )}

          {stepOtp && (
            <>
              <TextField
                id="outlined-basic"
                placeholder="Enter the verification code sent to your email"
                variant="outlined"
                sx={{ width: 300 }}
                onChange={(i) => setGetcode(i.target.value)}
                helperText={!isValidCode ? message : ""}
                error={!isValidCode}
              />
              <br />
              <Button variant="contained" className="btn" onClick={handleClick}>
                Unsubscribe
              </Button>
            </>
          )}

          {notUser && (
            <Box>
              <Typography sx={{ fontSize: 20, mt: 5 }}>{message}</Typography>
            </Box>
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default Unsubscribe;
