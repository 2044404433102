import React from "react";
import { Typography, Box, Avatar, CardContent, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/system";
import "./style.css";

const CustCard = styled("Card")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  maxWidth: 450,
  height: 565,
  borderRadius: 10,
  paddingTop: 50,
  transition: "0.2s ease-out",
  border: "2px solid #c3c6ce",
  "&:hover": {
    borderColor: "#008bf8",
    boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.25)",
    transform: "scale(1.02)",
  },
});

const PricingCard = (props) => {
  const {
    recommended,
    planName,
    icon,
    list,
    price,
    primaryBtn,
    primaryBtnFun,
    secondaryBtn,
    secondaryBtnFun,
    extrabtn,
  } = props;
  return (
    <CustCard>
      {recommended && (
        <div className="ribbon">
          <span>Best Value</span>
        </div>
      )}

      <Typography gutterBottom variant="h4" component="div" className="title">
        {planName}
      </Typography>
      <Box component="div" className="avatar-box">
        <Avatar
          alt="User"
          src={icon}
          variant="rounded"
          sx={{ width: 60, height: 60, marginTop: 3 }}
        />
      </Box>
      <Typography component="div" className="price-box">
        {price}
      </Typography>
      <CardContent>
        <Typography
          variant="body2"
          component="div"
          color="text.secondary"
          className="info-txt"
          sx={{ color: "#034C8C" }}
        >
          {list?.map((value) => (
            <Box component="p" variant="body1" className="info-txt-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              {value}
            </Box>
          ))}
        </Typography>
        <Box component="div" className="primary-button">
          <Button
            variant="contained"
            className="btn"
            onClick={primaryBtnFun}
          >
            {primaryBtn}
          </Button>
        </Box>
        {extrabtn && (
          <Box component="div" className="secondary-button">
            <Button
              variant="contained"
              sx={{
                marginTop: 3,
              }}
              className="btn"
              onClick={secondaryBtnFun}
            >
              {secondaryBtn}
            </Button>
          </Box>
        )}
      </CardContent>
    </CustCard>
  );
};
export default PricingCard;
