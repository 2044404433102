import React from "react";
import Header from "../components/Header";
import { Box, Container, Grid, Card, Typography } from "@mui/material";
import Footer from "../components/Footer";

function DownloadLink() {
  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: 12 }}>
        <Typography className="heading">Download the App</Typography>
      </Box>
      <Box
        component="div"
        sx={{
          marginTop: 6,
          marginBottom: 40,
          backgroundImage: "linear-gradient(to right, #2295C5, #24A984)",
          height: 400,
        }}
      >
        <Container Fixed sx={{ pt: 5 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: 24,
                fontFamily: "Poppins, sans-sarif",
                color: "white",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              Payment successful
            </Typography>
            <img
              src="assets/success.png"
              alt=""
              style={{
                height: 35,
                width: 35,
                marginLeft: 10,
              }}
            />
          </Box>
          <Card
            sx={{
              boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.25)",
              mt: 3,
              height: 400,
              pt: 5,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 28,
                fontWeight: "500",
                mb: 3,
                fontFamily: "Popppins, sans-sarif",
              }}
            >
              Scan the QR code below or download from the link
            </Typography>
            <Grid container>
              <Grid
                item
                lg={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src="assets/barcode1.jpg"
                  alt=""
                  class="barcode"
                  style={{ height: 200, width: 200 }}
                />
                <img
                  src="assets/playstore.png"
                  alt=""
                  class="link"
                  style={{ width: 150, height: 50, paddingTop: 20 }}
                />
              </Grid>
              <Grid
                item
                lg={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src="assets/barcode1.jpg"
                  alt=""
                  class="barcode"
                  style={{ height: 200, width: 200 }}
                />
                <img
                  src="assets/applestore.png"
                  alt=""
                  class="link"
                  style={{ width: 150, height: 50, paddingTop: 20 }}
                />
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default DownloadLink;
