import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

function goToHome() {
  window.open("https://www.futuredata.info/", "_blank");
}

function Header() {
  return (
    <AppBar sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="assets/Niada.jpg"
            alt="logo"
            height="50"
            width="200"
            onClick={goToHome}
          />

          <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
            <Button
              sx={{
                textDecoration: "none",
                color: "#3EBAFF",
                fontSize: 20,
              }}
              onClick={goToHome}
            >
              Home
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
