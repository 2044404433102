import React from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/system";

const SubLinks = styled("Typography")({
  fontSize: 24,
  marginBottom: 8,
});

const LinkHead = styled("Typography")({
  fontSize: 35,
  marginBottom: 50,
  fontWeight: "bold",
});

function Footer() {
  return (
    <Box>
      <Box>
        <Grid
          container
          sx={{
            background: "linear-gradient(to right, #2295C5, #24A984)",
            height: 600,
            color: "#FFFF",
            pt: 10,
          }}
        >
          <Grid item xs={3} sx={{ pl: 6 }}>
            <Box display="flex" flexDirection="column">
              <img
                src="assets/future-data.png"
                alt=""
                height="100"
                width="100"
                style={{ marginBottom: 50 }}
              />
              <SubLinks>
                Driving Efficiency in Public
                <br />
                Health With Artificial
                <br />
                Intelligence
              </SubLinks>
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ pl: 6 }}>
            <Box display="flex" flexDirection="column">
              <LinkHead>QUICK LINKS</LinkHead>
              <SubLinks>Home</SubLinks>
              <SubLinks>What We Are Solving</SubLinks>
              <SubLinks>Our Solution</SubLinks>
              <SubLinks>Partner with Us</SubLinks>
              <SubLinks>Who We Are</SubLinks>
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ pl: 6 }}>
            <Box display="flex" flexDirection="column">
              <LinkHead>GET IN TOUCH</LinkHead>
              <SubLinks>Tel: +1 408-464-2253</SubLinks>
              <SubLinks sx={{ mb: 6 }}>Email: hello@futuredata.info</SubLinks>
              <LinkHead sx={{ mb: 4 }}>NEWSLETTER</LinkHead>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Enter your email"
                  variant="outlined"
                  sx={{
                    width: 350,
                    input: {
                      color: "#FFFF",
                    },

                    fieldset: { borderColor: "#FFFF" },
                  }}
                  color="secondary"
                />
                <Button
                  variant="contained"
                  sx={{
                    mt: -6,
                    ml: 23,
                    backgroundColor: "white",
                    color: "black",
                    width: 150,
                  }}
                >
                  Subscribes
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ pl: 6 }}>
            <Box display="flex" flexDirection="column">
              <LinkHead>ADDRESS</LinkHead>
              <SubLinks>
                4121 N Morning View Way.
                <br />
                Lehi, UT, USA,
                <br />
                84043
              </SubLinks>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ backgroundColor: "#1E8C76", height: 50 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography sx={{ color: "white", fontSize: 24 }}>
          Copyright © 2023 Future Data. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
